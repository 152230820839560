@import '../../../../../variables.scss';

$idle-color: $pt-intent-primary;
$motion-color: rgb(240, 240, 0);
$sun-color: rgba(221, 221, 221);
$offset-color: transparent;

.ProfileV2Editor {
	.conditionBlocks {
		display: flex;
		flex-direction: column;
		gap: 16px;

		.ConditionBlock {
			background: #fafafa;
			border: 1px solid #b9b9b9;
			border-radius: 8px;
			// overflow: hidden;

			& .brightness-table-wrapper {
				position: relative;
				border-radius: 8px;
				overflow: hidden;
				pointer-events: none;
				& table {
					width: 100%;
					pointer-events: all;

					& tr {
						&.dayRow {
							background-color: #ddd;
						}

						&.nightRow {
							& td {
								background-color: rgba(0, 0, 0, 0.25);
							}
						}

						& td,
						& th {
							text-align: center;
							vertical-align: middle;
						}
					}

					& .num-cell {
						font-weight: 600;
					}
				}
			}

			.brightness-input {
				& .bp5-input-group:has(input) {
					display: flex;
					flex: 1;

					& input {
						flex: 1;
						width: 6ch;
					}
				}
			}

			& .row-action {
				display: flex;
				& .tickerAction {
					margin-inline: auto;
					pointer-events: all;
					opacity: 1;
				}
			}

			.header {
				background: #e4e4e4;
				border-radius: 8px;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				border-bottom: 1px solid #ccc;

				.padded {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.bp5-control {
						margin-bottom: 0;
					}
				}
			}

			.padded {
				padding: 8px 12px;
			}

			h4 {
				margin: 10px 0 10px;
			}

			.conditions {
				border-bottom: 1px solid #ddd;
				padding-bottom: 1px;
				padding-inline: 12px;

				h4 {
					display: flex;
					align-content: center;
					align-items: center;
					gap: 4px;
				}

				.noConditions {
					padding: 10px 0 24px;
					text-align: center;
					color: #777;
				}

				.Condition {
					border-top: 1px solid #ddd;
					padding: 8px 4px;
					display: flex;
					align-items: center;

					.icon {
						margin-right: 8px;
						color: #666;
					}

					.label {
						flex-grow: 1;
						font-weight: bold;
						font-size: 13px;
					}

					.value {
						align-items: end;
						display: flex;

						.smallNumeric input {
							width: 46px;
							text-align: center;
						}

						.small {
							font-size: 12px;
							color: #777;
							margin-top: 4px;
						}

						.lineBreak {
							margin-left: 18px;
							display: flex;
							flex-direction: column;
							align-items: end;
						}
					}

					.removeButton {
						margin-left: 10px;
					}
				}
			}

			.DayBrightnessEditor {
				background: #666;
				border-radius: 6px;
				padding: 10px;
				height: 200px;
				user-select: none;
				position: relative;
				margin-block: 36px;

				.sunsetSunriseOverlay {
					position: absolute;
					top: 0;
					bottom: 0;

					pointer-events: none;
					z-index: 5;

					background-color: transparentize($sun-color, 0.4);

					&:not(.left) {
						&::before {
							content: '';
							position: absolute;
							left: 0;
							top: 0;
							transform: translateX(-100%);
							width: 0.5rem;
							height: 100%;
							background: linear-gradient(to right, transparent, transparentize($sun-color, 0.4));
						}
					}

					&:not(.right) {
						&::after {
							content: '';
							position: absolute;
							right: 0;
							top: 0;
							transform: translateX(100%);
							width: 0.5rem;
							height: 100%;
							background: linear-gradient(to left, transparent, transparentize($sun-color, 0.4));
						}
					}
				}

				.periods {
					height: 100%;
					position: relative;
					border-inline: 1px solid #888;

					.period {
						height: 100%;
						width: 20px;
						border-radius: 8px;
						position: absolute;

						.activeValueSlider,
						.idleValueSlider {
							left: 0;
							bottom: 0;
							right: 0;
							position: absolute;

							.handle {
								position: absolute;
								top: -9px;
								padding: 0 2px;
								border-radius: 6px;
								display: inline-block;
								width: 40px;
								margin-left: -20px;
								text-align: center;
								left: 50%;
								font-size: 11px;
								cursor: ns-resize;
								z-index: 8;
								// font-weight: bold;
							}
						}

						.activeValueSlider {
							background: transparentize($motion-color, 0.3);

							.handle {
								background: $motion-color;
							}
						}

						.idleValueSlider {
							background: $idle-color;

							.handle {
								background: $idle-color;
								color: white;
							}
						}

						.horizontalResizeHandle {
							background-color: #888;
							border-radius: 0;
							top: 0;
							bottom: 0;
							width: 1px;
							right: 0px;
							position: absolute;
							z-index: 7;

							&[data-disabled='true'] {
								&:hover {
									cursor: not-allowed;
								}

								&:active {
									cursor: not-allowed;
									pointer-events: none;
								}
							}

							&:hover:not([data-disabled='true']):not(:has(.ticker:hover)),
							&:active:not([data-disabled='true']):not(:has(.ticker:hover)) {
								outline: 3px solid red;
								cursor: ew-resize;
								z-index: 8;
							}

							&:has(.ticker:hover) {
								z-index: 9;
							}
						}

						&:last-child {
							.horizontalResizeHandle {
								display: none;
							}
						}

						& .handle[data-disabled='true'] {
							&:hover {
								cursor: not-allowed;
							}
							opacity: 0.5;
							background-color: #888;
						}
					}
				}

				.tickers {
					position: relative;
					display: block;
					height: 30px;

					&:hover {
						.tickerActionWrap {
							z-index: 11;

							.tickerAction {
								opacity: 1;
								pointer-events: all;
							}
						}
					}

					.tickerActionWrap {
						position: absolute;
						top: 6px;
						display: flex;
						align-items: center;
						justify-content: center;
						transform: translateX(-50%);

						&:hover {
							z-index: 12;
						}
					}
				}

				.ticker {
					position: absolute;
					border: 1px solid #000000;
					background: #cccccc;
					color: #000000;
					padding: 0 2px;
					border-radius: 6px;
					display: inline-block;

					transform: translateX(-50%);
					text-align: center;
					left: 0;
					font-size: 11px;
					display: flex;
					gap: 0.25rem;
					align-items: center;
					// pointer-events: none;

					&.informative {
						// bp5-disabled
						top: -28px;
						background-color: #f1f1f1;
						color: #5f6b7c;
						border-color: transparent;

						&:not(.side) {
							border-color: #5f6b7c65;
							z-index: 7;
						}
					}

					&:not(.informative) {
						bottom: -28px;
					}

					&.active {
						z-index: 9;
					}

					&.hover {
						cursor: default;
						z-index: 10;
					}
				}
			}
		}
	}

	.labelMargin {
		margin: 0 10px;
	}

	.DayBrightnessEditorLegend {
		float: right;
		display: flex;
		font-size: 12px;
		font-weight: normal;

		div {
			padding: 4px 8px;
			margin-left: 4px;
			border-radius: 4px;
		}

		.daylight {
			background-color: $sun-color;
		}

		.idle {
			background: $idle-color;
			color: white;
		}
		.active {
			background: $motion-color;
		}
	}
}

.tickerAction {
	background: #eee;
	border-radius: 20px;
	box-shadow: 0 0 2px black;
	overflow: hidden;
	opacity: 0;
	pointer-events: none;

	transition: opacity 150ms ease-in-out;

	.bp5-button {
		font-size: 10px;
	}
}

.legend-block {
	display: flex;
	align-items: center;
	justify-content: space-between;

	& label.bp5-checkbox {
		margin-bottom: 0;
	}
	margin-bottom: 0.25rem;
}
