@import '../../variables.scss';

@mixin baseTechPanel() {
	background-color: rgba(255, 255, 255, 0.5);
	backdrop-filter: blur(0.33rem);
	// background-color: white;
	box-shadow: 0 0 0.5rem 0rem rgba(0, 0, 0, 0.3);
	padding: 0.5rem;
	border-radius: 0.5rem;
}

.technician-page {
	& .Map {
		& > .tools {
			pointer-events: none;
			z-index: 14;
			height: calc(100% - 20px);

			& button {
				width: 2.5rem;
				height: 2.5rem;
				pointer-events: all;
			}

			& #locate-btn {
				margin-top: auto;
			}
		}
	}

	& * {
		font-size: large;
	}

	height: 100dvh;
	width: 100vw;
	background-color: white;

	position: relative;

	& .mapboxgl-ctrl-bottom-left,
	& .mapboxgl-ctrl-bottom-right {
		display: none;
	}

	& > .overlay {
		pointer-events: none;
		z-index: 15;
		position: absolute;
		// width: clamp(0rem, 100%, 30rem);
		width: 100%;
		left: 50%;
		transform: translateX(-50%);
		height: 100%;

		display: flex;
		flex-direction: column;

		padding: 0.5rem;
		gap: 1rem;

		& .account {
			pointer-events: all;
			@include baseTechPanel();

			align-self: flex-start;
		}

		& > .details {
			display: flex;
			flex-direction: column;
			position: relative;
			& .close-btn {
				position: absolute;
				top: 0.25rem;
				right: 0.25rem;
				font-size: 1.5rem;
				color: red;
				display: grid;
				place-items: center;
			}

			@include baseTechPanel();
			flex: 1;

			&[data-inactive='true'] {
				pointer-events: none;
				opacity: 0;
			}

			&:not([data-inactive='true']) {
				opacity: 1;
				pointer-events: all;
			}

			& > .details__body {
				display: flex;
				flex-direction: column;
				gap: 1rem;

				& .device-id {
					font-size: larger;

					& > * {
						font-size: larger;
					}
				}

				& .location-group {
					display: flex;
					flex-direction: column;
					gap: 0.25rem;

					& > div {
						display: flex;
						gap: 0.25rem;

						& button {
							text-align: center;
						}

						& > * {
							flex: 1;
						}

						& .coordinates {
							display: flex;
							flex-direction: column;
							gap: 0.25rem;

							& > * {
								flex: 1;
							}
						}
					}
				}
			}
		}

		& > .enter {
			pointer-events: all;

			&.panel {
				@include baseTechPanel();

				& > * {
					flex: 1;
				}
			}

			&:not(.panel) {
				width: fit-content;
			}

			display: flex;
			gap: 0.5rem;

			& button {
				display: flex;
				gap: 0.5rem;
			}
		}
	}
}

.center-pin {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 50%;
	background-color: red;
	border: 2px solid blue;
}

.device-actions {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	& > div {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		& > * {
			flex: 1;
		}
	}
}

.top-left {
	display: flex;
	gap: 0.5rem;
}

.save-btn {
	margin-top: auto;
}

.inspector-button {
	width: 2.5rem;
	height: 2.5rem;

	& i {
		color: #5f6b7c;
	}
}

.selecting-pin-btn {
	display: flex;
	gap: 0.5rem;

	& > * {
		flex: 1;
	}
}

.info-block {
	display: flex;
	gap: 0.5rem;
}

.technician-dialog {
	& * {
		font-size: large;
	}
}
