.devices-tab {
	display: flex;
	flex-direction: column;

	& header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #ddd;
		background-color: white;
		padding: 4px 0;
	}
}

.SearchInput {
	flex-grow: 1;
	display: flex;
	align-items: center;
	padding: 0 4px;

	input {
		margin-left: 4px;
		font-size: 12px;
		border: none;
		flex: 1;
	}
}
