@import '../../../../variables.scss';

.add-device-block {
	@include map-ui-piece;
	width: 340px;
	gap: 0.5rem;

	& > .close-btn {
		position: absolute;
		right: 8px;
		top: 8px;
	}

	& > .title {
		text-align: center;
		font-weight: bold;
		font-size: 1.5rem;
		margin-bottom: 8px;
	}

	& > .footer {
		margin-top: auto;
		display: flex;
		justify-content: flex-end;
	}

	& > .id-input {
		width: 100%;
		resize: none;
	}

	& > .device-count-hint {
		margin-top: 0.5rem;
	}
}
