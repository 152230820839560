.icon-text-box {
	margin-top: 0.125rem;
	display: flex;
	gap: 0.25rem;
	align-items: center;

	& > .icon-wrapper {
		min-width: 1.25rem;
		display: flex;
		justify-content: center;
		align-items: center;

		& i {
			font-size: 0.65rem;
		}
	}

	& > .content {
		flex: 1;
	}
}
