.profile-icon {
	box-sizing: border-box;
	height: var(--size);
	width: var(--size);
	aspect-ratio: 1/1;

	display: grid;
	place-items: center;
	border-radius: 100%;

	& i {
		color: white;
		font-size: calc(var(--size) * 0.75);
	}
}
